import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function PrivacyPage() {
  return (
    <Layout>
      <Seo title="Privacy Policy" />
      <Hero />
      <Policy />
    </Layout>
  )
}

function Hero() {
  return (
    <section className="relative bg-gray-50 overflow-hidden">
      <div
        className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full"
        aria-hidden="true"
      >
        <div className="relative h-full max-w-7xl mx-auto">
          <svg
            className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
            />
          </svg>
        </div>
      </div>

      <div className="relative my-16 mx-auto max-w-7xl px-4 sm:my-24">
        <div className="text-center">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="xl:inline">Privacy</span>{" "}
            <span className="text-indigo-600 xl:inline">Policy</span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8"></div>
        </div>
      </div>
    </section>
  )
}

function Policy() {
  const definitions = [
    {
      term: "Account",
      definition:
        "means a unique account created for You to access our Service or parts of our Service.",
    },
    {
      term: "Affiliate",
      definition:
        'means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.',
    },
    {
      term: "Application",
      definition:
        "means the software program provided by the Company downloaded by You on any electronic device, named Qwarkle",
    },
    {
      term: "Company",
      definition:
        '(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Qwarkle.',
    },
    {
      term: "Country",
      definition: "refers to: Tennessee, United States",
    },
    {
      term: "Device",
      definition:
        "means any device that can access the Service such as a computer, a cellphone or a digital tablet.",
    },
    {
      term: "Personal Data",
      definition:
        "is any information that relates to an identified or identifiable individual.",
    },
    {
      term: "Service",
      definition: "refers to the Application.",
    },
    {
      term: "Service Provider",
      definition:
        "means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.",
    },
    {
      term: "Usage Data",
      definition:
        "refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).",
    },
    {
      term: "You",
      definition:
        "means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.",
    },
  ]
  const perDataOne = [
    {
      term: "To provide and maintain our Service,",
      definition: "including to monitor the usage of our Service.",
    },
    {
      term: "To manage Your Account:",
      definition:
        "to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.",
    },
    {
      term: "For the performance of a contract:",
      definition:
        "the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.",
    },
    {
      term: "To contact You:",
      definition:
        "To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.",
    },
    {
      term: "To provide You",
      definition:
        "with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.",
    },
    {
      term: "To manage Your requests:",
      definition: "To attend and manage Your requests to Us.",
    },
    {
      term: "For business transfers:",
      definition:
        "We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.",
    },
    {
      term: "For other purposes:",
      definition:
        "We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.",
    },
  ]
  const perDataTwo = [
    {
      term: "With Service Providers:",
      definition:
        "We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.",
    },
    {
      term: "For business transfers:",
      definition:
        "We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.",
    },
    {
      term: "With Affiliates:",
      definition:
        "We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.",
    },
    {
      term: "With business partners:",
      definition:
        "We may share Your information with Our business partners to offer You certain products, services or promotions.",
    },
    {
      term: "With other users:",
      definition:
        "when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.",
    },
    {
      term: "With Your consent:",
      definition:
        "We may disclose Your personal information for any other purpose with Your consent.",
    },
  ]
  const other = [
    {
      term: "Comply with a legal obligation",
    },
    {
      term: "Protect and defend the rights or property of the Company",
    },
    {
      term: "Prevent or investigate possible wrongdoing in connection with the Service",
    },
    {
      term: "Protect the personal safety of Users of the Service or the public",
    },
    {
      term: "Protect against legal liability",
    },
  ]
  const contact = [
    {
      term: "By email:",
      definition: "seth@rawiedemann.com",
    },
    {
      term: "By mail:",
      definition: "P.O. Box 621 Georgetown, KY 40324",
    },
  ]
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-20 px-4 sm:px-6 lg:py-28 lg:px-8">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <h2 className="text-3xl font-extrabold text-gray-900">
              Privacy Policy
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              We use Your Personal data to provide and improve the Service. By
              using the Service, You agree to the collection and use of
              information in accordance with this Privacy Policy. This Privacy
              Policy has been created with the help of the Privacy Policy
              Template.
            </p>
            <p className="text-lg mt-4 text-gray-500">
              Last updated:{" "}
              <span className="font-medium">January 27, 2022</span>
            </p>
          </div>
          <div className="mt-12 lg:mt-0 lg:col-span-2">
            <dl className="space-y-4">
              <div className="space-y-4">
                <h2 className="text-2xl leading-6 font-semibold text-gray-900">
                  Interpretation and Definitions
                </h2>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Interpretation
                </dt>
                <dd className="text-base text-gray-500">
                  The words of which the initial letter is capitalized have
                  meanings defined under the following conditions. The following
                  definitions shall have the same meaning regardless of whether
                  they appear in singular or in plural.
                </dd>
              </div>
              <div className="space-y-4">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Definitions
                </dt>
                <dd className="text-base text-gray-500 space-y-4">
                  <p>For the purposes of this Privacy Policy:</p>
                  <ul className="list-disc px-8 space-y-4">
                    {definitions.map((item, i) => (
                      <li key={i}>
                        <span className="font-bold">{item.term}</span>{" "}
                        {item.definition}
                      </li>
                    ))}
                  </ul>
                </dd>
              </div>
              <div className="space-y-4">
                <h2 className="text-2xl leading-6 font-semibold text-gray-900">
                  Collecting and Using Your Personal Data
                </h2>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Types of Data Collected
                </dt>
                <dd className="text-base text-gray-500 space-y-4">
                  <p className="text-base font-medium text-gray-800">
                    Personal Data
                  </p>
                  <p>
                    While using Our Service, We may ask You to provide Us with
                    certain personally identifiable information that can be used
                    to contact or identify You. Personally identifiable
                    information may include, but is not limited to:
                  </p>
                  <ul className="list-disc px-8 space-y-4">
                    <li>Email address</li>
                    <li>First name and last name</li>
                    <li>Usage data</li>
                  </ul>
                  <p className="text-base font-medium text-gray-800">
                    Usage Data
                  </p>
                  <p>
                    Usage Data is collected automatically when using the
                    Service.
                  </p>
                  <p>
                    Usage Data may include information such as Your Device's
                    Internet Protocol address (e.g. IP address), browser type,
                    browser version, the pages of our Service that You visit,
                    the time and date of Your visit, the time spent on those
                    pages, unique device identifiers and other diagnostic data.
                  </p>
                  <p>
                    When You access the Service by or through a mobile device,
                    We may collect certain information automatically, including,
                    but not limited to, the type of mobile device You use, Your
                    mobile device unique ID, the IP address of Your mobile
                    device, Your mobile operating system, the type of mobile
                    Internet browser You use, unique device identifiers and
                    other diagnostic data.
                  </p>
                  <p>
                    We may also collect information that Your browser sends
                    whenever You visit our Service or when You access the
                    Service by or through a mobile device.
                  </p>
                </dd>
              </div>

              <div className="space-y-4">
                <dt className="text-2xl leading-6 font-medium text-gray-900">
                  Use of Your Personal Data
                </dt>
                <dd className="text-base text-gray-500 space-y-4">
                  <p>
                    The Company may use Personal Data for the following
                    purposes:
                  </p>
                  <ul className="list-disc px-8 space-y-4">
                    {perDataOne.map((item, i) => (
                      <li key={i}>
                        <span className="font-bold">{item.term}</span>{" "}
                        {item.definition}
                      </li>
                    ))}
                  </ul>
                  <p>
                    We may share Your personal information in the following
                    situations:
                  </p>
                  <ul className="list-disc px-8 space-y-4">
                    {perDataTwo.map((item, i) => (
                      <li key={i}>
                        <span className="font-bold">{item.term}</span>{" "}
                        {item.definition}
                      </li>
                    ))}
                  </ul>
                </dd>
              </div>

              <div className="space-y-4">
                <dt className="text-2xl leading-6 font-medium text-gray-900">
                  Retention of Your Personal Data
                </dt>
                <dd className="text-base text-gray-500 space-y-4">
                  <p>
                    The Company will retain Your Personal Data only for as long
                    as is necessary for the purposes set out in this Privacy
                    Policy. We will retain and use Your Personal Data to the
                    extent necessary to comply with our legal obligations (for
                    example, if we are required to retain your data to comply
                    with applicable laws), resolve disputes, and enforce our
                    legal agreements and policies.
                  </p>
                  <p>
                    The Company will also retain Usage Data for internal
                    analysis purposes. Usage Data is generally retained for a
                    shorter period of time, except when this data is used to
                    strengthen the security or to improve the functionality of
                    Our Service, or We are legally obligated to retain this data
                    for longer time periods.
                  </p>
                </dd>
              </div>
              <div className="space-y-4">
                <dt className="text-2xl leading-6 font-medium text-gray-900">
                  Transfer of Your Personal Data
                </dt>
                <dd className="text-base text-gray-500 space-y-4">
                  <p>
                    Your information, including Personal Data, is processed at
                    the Company's operating offices and in any other places
                    where the parties involved in the processing are located. It
                    means that this information may be transferred to — and
                    maintained on — computers located outside of Your state,
                    province, country or other governmental jurisdiction where
                    the data protection laws may differ than those from Your
                    jurisdiction.
                  </p>
                  <p>
                    Your consent to this Privacy Policy followed by Your
                    submission of such information represents Your agreement to
                    that transfer.
                  </p>
                  <p>
                    The Company will take all steps reasonably necessary to
                    ensure that Your data is treated securely and in accordance
                    with this Privacy Policy and no transfer of Your Personal
                    Data will take place to an organization or a country unless
                    there are adequate controls in place including the security
                    of Your data and other personal information.
                  </p>
                </dd>
              </div>
              <div className="space-y-4">
                <h2 className="text-2xl leading-6 font-medium text-gray-900">
                  Disclosure of Your Personal Data
                </h2>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Business Transactions
                </dt>
                <dd className="text-base text-gray-500 space-y-4">
                  If the Company is involved in a merger, acquisition or asset
                  sale, Your Personal Data may be transferred. We will provide
                  notice before Your Personal Data is transferred and becomes
                  subject to a different Privacy Policy.
                </dd>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Law enforcement
                </dt>
                <dd className="text-base text-gray-500 space-y-4">
                  Under certain circumstances, the Company may be required to
                  disclose Your Personal Data if required to do so by law or in
                  response to valid requests by public authorities (e.g. a court
                  or a government agency).
                </dd>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Other legal requirements
                </dt>
                <dd className="text-base text-gray-500 space-y-4">
                  <p>
                    The Company may disclose Your Personal Data in the good
                    faith belief that such action is necessary to:
                  </p>
                  <ul className="list-disc px-8 space-y-4">
                    {other.map((item, i) => (
                      <li key={i}>{item.term}</li>
                    ))}
                  </ul>
                </dd>
              </div>
              <div className="space-y-4">
                <dt className="text-2xl leading-6 font-medium text-gray-900">
                  Security of Your Personal Data
                </dt>
                <dd className="text-base text-gray-500 space-y-4">
                  The security of Your Personal Data is important to Us, but
                  remember that no method of transmission over the Internet, or
                  method of electronic storage is 100% secure. While We strive
                  to use commercially acceptable means to protect Your Personal
                  Data, We cannot guarantee its absolute security.
                </dd>
              </div>
              <div className="space-y-4">
                <dt className="text-2xl leading-6 font-medium text-gray-900">
                  Children's Privacy
                </dt>
                <dd className="text-base text-gray-500 space-y-4">
                  <p>
                    Our Service does not address anyone under the age of 13. We
                    do not knowingly collect personally identifiable information
                    from anyone under the age of 13. If You are a parent or
                    guardian and You are aware that Your child has provided Us
                    with Personal Data, please contact Us. If We become aware
                    that We have collected Personal Data from anyone under the
                    age of 13 without verification of parental consent, We take
                    steps to remove that information from Our servers.
                  </p>
                  <p>
                    If We need to rely on consent as a legal basis for
                    processing Your information and Your country requires
                    consent from a parent, We may require Your parent's consent
                    before We collect and use that information.
                  </p>
                </dd>
              </div>
              <div className="space-y-4">
                <dt className="text-2xl leading-6 font-medium text-gray-900">
                  Links to Other Websites
                </dt>
                <dd className="text-base text-gray-500 space-y-4">
                  <p>
                    Our Service may contain links to other websites that are not
                    operated by Us. If You click on a third party link, You will
                    be directed to that third party's site. We strongly advise
                    You to review the Privacy Policy of every site You visit.
                  </p>
                  <p>
                    We have no control over and assume no responsibility for the
                    content, privacy policies or practices of any third party
                    sites or services.
                  </p>
                </dd>
              </div>
              <div className="space-y-4">
                <dt className="text-2xl leading-6 font-medium text-gray-900">
                  Changes to this Privacy Policy
                </dt>
                <dd className="text-base text-gray-500 space-y-4">
                  <p>
                    We may update Our Privacy Policy from time to time. We will
                    notify You of any changes by posting the new Privacy Policy
                    on this page.
                  </p>
                  <p>
                    We will let You know via email and/or a prominent notice on
                    Our Service, prior to the change becoming effective and
                    update the "Last updated" date at the top of this Privacy
                    Policy.
                  </p>
                  <p>
                    You are advised to review this Privacy Policy periodically
                    for any changes. Changes to this Privacy Policy are
                    effective when they are posted on this page.
                  </p>
                </dd>
              </div>
              <div className="space-y-4">
                <dt className="text-2xl leading-6 font-medium text-gray-900">
                  Contact Us
                </dt>
                <dd className="text-base text-gray-500 space-y-4">
                  <p>
                    If you have any questions about this Privacy Policy, You can
                    contact us:
                  </p>
                  <ul className="list-disc px-8 space-y-4">
                    {contact.map((item, i) => (
                      <li key={i}>
                        <span className="font-bold">{item.term}</span>{" "}
                        {item.definition}
                      </li>
                    ))}
                  </ul>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}
